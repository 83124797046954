//js
if (!global._babelPolyfill) {
  require('babel-polyfill');
}
require('./js/slider');
require('./js/scroll');
require('./js/helper');
require('./js/navigation');
require('./js/maps');
require('./js/main');
require('./js/fixHamburger');
require('./js/showNumber');
require('./js/cmtAsPartnerTiles');

//fonts
// require('./fonts/font/stylesheet.css');

// css
import 'normalize.css';
require('./sass/style.scss');
