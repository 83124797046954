// https://github.com/kenwheeler/slick

import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import { isMobile } from './helper';

/****** SLIDERS *******/
const sliders = {
  home: $('.home-slider'),
  postsByAuthor: $('#authors-slide .authors-list'),
  postsByCategory: $('#categories-slide .list-of-categories'),
  members: $('.assigned-members-slider .inner'),
  featuredAcademyPosts: $('.academy-header-wrapper .meta')
};

sliders.featuredAcademyPosts.slick({
  arrows: false,
  fade: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 5500
});

sliders.members.slick({
  dots: false,
  draggable: false,
  arrows: true,
  appendArrows: $('.assigned-members-slider .slider-nav'),
  slidesToShow: 5,
  slidesToScroll: 1,
  prevArrow:
    '<button class="prev-arrow"><span><svg width="10px" height="17px" viewBox="0 0 10 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Project-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="07.wpis" transform="translate(-1323.000000, -2212.000000)" fill="#44B0CB"><path d="M1321.37132,2214.37132 L1321.37132,2223.82032 L1330.82107,2223.82107 L1330.82107,2225.82107 L1319.82107,2225.82107 L1319.82032,2225.37132 L1319.37132,2225.37132 L1319.37132,2214.37132 L1321.37132,2214.37132 Z" id="Combined-Shape" transform="translate(1325.096194, 2220.096194) rotate(-135.000000) translate(-1325.096194, -2220.096194) "></path></g></g></svg></span></button>',
  nextArrow:
    '<button class="next-arrow"><span><svg width="10px" height="17px" viewBox="0 0 10 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Project-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="07.wpis" transform="translate(-1323.000000, -2212.000000)" fill="#44B0CB"><path d="M1321.37132,2214.37132 L1321.37132,2223.82032 L1330.82107,2223.82107 L1330.82107,2225.82107 L1319.82107,2225.82107 L1319.82032,2225.37132 L1319.37132,2225.37132 L1319.37132,2214.37132 L1321.37132,2214.37132 Z" id="Combined-Shape" transform="translate(1325.096194, 2220.096194) rotate(-135.000000) translate(-1325.096194, -2220.096194) "></path></g></g></svg></span></button>',
  responsive: [
    {
      breakpoint: 1442,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

const btns = document.querySelectorAll('.assigned-members-slider .clickable');
const descriptions = document.querySelectorAll('.assigned-members-slider .description');
btns.forEach(btn => {
  btn.addEventListener('click', () => {
    descriptions.forEach(item => {
      item.classList.remove('show');
    });
    const index = btn.closest('.slick-slide').getAttribute('data-slick-index');
    if (sliders.members.slick('slickCurrentSlide') !== index) {
      sliders.members.slick('slickGoTo', index);
    }
    const parent = btn.closest('.slide-wrapper');
    parent.querySelector('.description').classList.toggle('show');
  });
});

sliders.home.slick({
  dots: false,
  autoplay: false
});

sliders.postsByAuthor.slick({
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToScroll: 1,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 2
      }
    }
  ]
});

sliders.postsByCategory.slick({
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToScroll: 1,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 2
      }
    }
  ]
});
