export default class OfferPagesLinks {
  constructor(container) {
    this.container = container;
    this.terms = container.querySelectorAll('.term');
    this.init();
  }

  init() {
    this.terms.forEach(term => {
      term.addEventListener('click', e => {
        e.preventDefault();
        let href = term.querySelector('a').href;
        window.location.href = href;
      });
    });
  }
}
