const advisorySubpagesMenuHandler = container => {
  const openMenuBtn = container.querySelector('.select-button a');
  const menuWrapper = container.querySelector('.advisory-pages-links');
  const defaultBtnText = openMenuBtn.textContent;

  openMenuBtn.addEventListener('click', e => {
    e.preventDefault();
    const parent = menuWrapper.closest('.advisory-pages');
    parent.classList.toggle('show');
    if (parent.classList.contains('show')) {
      openMenuBtn.textContent = 'Menu:';
    } else {
      openMenuBtn.textContent = defaultBtnText;
    }
  });
};

export default advisorySubpagesMenuHandler;
