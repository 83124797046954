// import $ from 'jquery';
import { handleDictionaryPopup, isMobile, showPhoneNumber } from './helper';
import SiteHeader from './siteHeader';
import {
  toggleMenu,
  addSticky,
  closeMenu,
  handleMobileMenu,
  academyCategoriesMenu,
  academyCategoriesMenuMobile,
  academyPostsMenu
} from './navigation';
import FloatLabels from './forms/float-labels';
import FrontPageValues from './frontPageValues';
import SearchHandler from './searchHandler';
// import Popup from './teamMemberPopup';
import OfferPagesLinks from './offerPagesLinks';
import advisorySubpagesMenuHandler from './advisorySubpagesMenu';

window.addEventListener('load', () => {
  new SiteHeader();
  new FloatLabels();
  new SearchHandler();

  const mouseIcon = document.querySelector('.header-mouse-icon');
  if (mouseIcon) {
    mouseIcon.addEventListener('click', e => {
      const selectedSection = document.querySelector('#main-page-values');
      selectedSection.scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  let academyPostsMenuContainer = document.querySelector('.assigned-academy-menu-container');
  if (isMobile()) {
    academyPostsMenuContainer = document.querySelector('.assigned-academy-menu-container.mobile');
  }
  if (academyPostsMenuContainer) {
    academyPostsMenu(academyPostsMenuContainer);
  }

  const academyCategoriesWrapper = document.querySelector('.academy-categories-wrapper');
  if (academyCategoriesWrapper) {
    if (!isMobile()) {
      academyCategoriesMenu(academyCategoriesWrapper);
    } else {
      academyCategoriesMenuMobile(academyCategoriesWrapper);
    }
  }

  const dictionaryPopupContainer = document.querySelector('.dictionary-popup-wrapper');
  if (dictionaryPopupContainer) {
    handleDictionaryPopup(dictionaryPopupContainer);
  }

  const hidePhoneNumberSpan = document.querySelector('.phone-cover');
  if (hidePhoneNumberSpan) {
    showPhoneNumber(hidePhoneNumberSpan);
  }

  // const check = document.querySelector('.open-popup');
  // if (check) {
  //   new Popup();
  // }

  if (document.body.classList.contains('home')) {
    new FrontPageValues();
  }

  toggleMenu();

  if (isMobile()) {
    handleMobileMenu();
    closeMenu();
    addSticky();

    const linksContainer = document.querySelector('.offer-pages-container');
    if (linksContainer) {
      new OfferPagesLinks(linksContainer);
    }

    const advisorySubpagesMenuContainer = document.querySelector('.categories-list.advisory-pages');
    if (advisorySubpagesMenuContainer) {
      advisorySubpagesMenuHandler(advisorySubpagesMenuContainer);
    }
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
