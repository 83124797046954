import CoreAnimation from './animations/CoreAnimation';

export default class FrontPageValues {
  constructor() {
    this.container = document.querySelector('.front-page-values');
    this.headings = this.container.querySelectorAll('.left .single-value');
    this.images = this.container.querySelectorAll('.right figure');
    this.currentItem = null;
    this.prevItem = null;

    this.init();
  }

  init() {
    this.setActive();

    this.headings.forEach(element => {
      element.addEventListener('click', e => {
        if (!element.classList.contains('active')) {
          this.removeActive();
          this.prevItem = this.currentItem;
          this.currentItem = element.querySelector('.value-content');
          CoreAnimation.slideUp(this.prevItem);
          CoreAnimation.slideDown(this.currentItem);

          element.classList.add('active');
          this.displayImage(element.dataset.id);
        }
      });
    });
  }

  displayImage(image) {
    this.images[image].classList.add('active');
  }

  setActive() {
    CoreAnimation.slideDown(this.headings[0].querySelector('.value-content'));
    this.currentItem = this.headings[0].querySelector('.value-content');
    this.headings[0].classList.add('active');
    this.images[0].classList.add('active');
  }

  removeActive() {
    this.headings.forEach(el => {
      el.classList.remove('active');
    });

    this.images.forEach(el => {
      el.classList.remove('active');
    });
  }
}
