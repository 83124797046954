/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import { getScreenHeight } from './helper.js';
import CoreAnimation from './animations/CoreAnimation';

const buildSubmenuHeadings = container => {
  const headingText = container.querySelector('a').textContent;
  const submenuUl = container.querySelector('ul');

  const goBackLink = document.createElement('a');
  goBackLink.classList.add('go-back-one-level');
  goBackLink.href = '#0';
  goBackLink.innerHTML = '&laquo; ' + headingText;
  submenuUl.prepend(goBackLink);

  goBackLink.addEventListener('click', e => {
    const parentLi = goBackLink.closest('.show-submenu');
    parentLi.classList.remove('show-submenu');
  });
};

export const handleMobileMenu = () => {
  const linksWithSubmenu = [...document.querySelectorAll('.main-navigation li.menu-item-has-children > a')];
  linksWithSubmenu.forEach(link => {
    const parent = link.parentNode;

    buildSubmenuHeadings(parent);

    link.addEventListener('click', e => {
      e.preventDefault();
      parent.classList.add('show-submenu');
    });
  });
};

export const toggleMenu = () => {
  let button = document.getElementById('toggle'),
    container = document.getElementById('site-navigation'),
    html = document.querySelector('html');

  if (button && container) {
    button.addEventListener('click', () => {
      CoreAnimation.slideToggle(document.querySelector('#site-navigation > div > ul'));

      if (button.classList.contains('open')) {
        button.classList.remove('open');
        container.classList.remove('open');
        html.style.overflowY = 'auto';
        return;
      }

      button.classList.add('open');
      container.classList.add('open');

      if (getScreenHeight() <= 1260) {
        html.style.overflowY = 'hidden';
      } else {
        html.style.overflowY = 'auto';
      }
    });
  }
};

export const closeMenu = () => {
  // let links = document.querySelectorAll('.main-navigation a'),
  // nav = document.getElementById('site-navigation'),
  // links.forEach(e => {
  //   e.addEventListener('click', () => {
  //     nav.classList.remove('open');
  //     toggle.classList.remove('open');
  //   });
  // });
};

export const addSticky = () => {
  let toggle = document.getElementById('toggle'),
    header = document.getElementById('masthead');

  toggle.addEventListener('click', () => {
    header.classList.add('sticky');
  });
};

// export const academyCategoriesMenu = container => {
//   const parentBtns = container.querySelectorAll('.parent-categories__link');
//   parentBtns.forEach(btn => {
//     btn.addEventListener('click', e => {
//       e.preventDefault();
//       parentBtns.forEach(btn => {
//         btn.classList.remove('is-active-parent');
//       });
//       btn.classList.add('is-active-parent');
//     });
//   });
// };

export const academyCategoriesMenuMobile = container => {
  const showHideBtn = container.querySelector('.show-hide-btn');
  const list = container.querySelector('.parent-categories');
  showHideBtn.addEventListener('click', e => {
    e.preventDefault();
    list.classList.toggle('show');
  });
};

export const academyPostsMenu = container => {
  const topLvlItems = container.querySelectorAll('.menu > li > a');
  topLvlItems.forEach(item => {
    item.addEventListener('click', e => {
      e.preventDefault();
      const parent = item.closest('.menu-item');
      parent.classList.toggle('open');
    });
  });
};
