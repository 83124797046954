const cmtAsPartner = document.querySelector('.cmtAsPartner');
if (cmtAsPartner) {
  const lis = cmtAsPartner.querySelectorAll('li');
  if (lis && lis.length > 0) {
    lis.forEach(li => {
      const btn = li.querySelector('button');
      btn.addEventListener('click', () => {
        li.classList.toggle('active');
      });
    });
  }
}
