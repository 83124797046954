export default class SearchHandler {
  constructor() {
    this.popupContainer = document.querySelector('.search-popup');
    this.openButton = document.getElementById('open-search-box');
    this.resultsContainer = null;
    this.closeButton = null;
    this.input = null;
    this.inputValue = null;
    this.timeout = null;

    this.init();
  }

  init() {
    // const searchform = document.querySelector('.search-popup #searchform');
    // searchform.addEventListener('submit', e => {
    //   e.preventDefault();
    // });

    this.openButton.addEventListener('click', e => {
      e.preventDefault();
      this.popupContainer.classList.add('open');

      this.closeButton = document.getElementById('close-button');
      this.closeButton.addEventListener('click', () => {
        this.popupContainer.classList.remove('open');
      });
      // this.handleSearchBox();
    });
  }

  handleSearchBox() {
    this.clearResultsContainers();

    this.handleInput();
  }

  clearResultsContainers() {
    this.resultsContainer = document.querySelector('.search-results');

    const pages = this.resultsContainer.querySelector('.pages');
    const pagesMeta = pages.querySelector('.meta');
    pagesMeta.innerHTML = '';
    const pagesList = pages.querySelector('.list');
    pagesList.innerHTML = '';

    const posts = this.resultsContainer.querySelector('.posts');
    const postsMeta = posts.querySelector('.meta');
    postsMeta.innerHTML = '';
    const postsList = posts.querySelector('.list');
    postsList.innerHTML = '';

    this.input = document.querySelector('.search-popup form input');
    this.input.value = '';
    this.input.focus();
  }

  handleInput() {
    this.input.addEventListener('input', e => {
      clearTimeout(this.timeout);

      if (this.input.value.length > 2) {
        this.inputValue = this.input.value;

        this.timeout = setTimeout(() => {
          this.getResults('posts', this.inputValue);
          this.getResults('pages', this.inputValue);
        }, 500);
      }
    });
  }

  async getResults(type, value) {
    let lang = '';
    if (window.location.href.indexOf('/en') > -1) {
      lang = 'en/';
    }
    const posts = await fetch(
      `https://cmt-advisory.pl/${lang}wp-json/wp/v2/${type}?search=${value}&orderby=relevance&order=asc&per_page=15`
    )
      .then(res => res.json())
      .then(res => {
        if (res.length > 0) {
          this.handleResults(res, type);
        }
      });
  }

  handleResults(data, type) {
    this.resultsContainer = document.querySelector(`.search-results .${type} .list`);

    this.setHeadings(data, type);

    this.resultsContainer.innerHTML = '';

    data.forEach(result => {
      const resultHTML = `<a href='${result.link}'>${result.title.rendered}</a>`;
      this.resultsContainer.innerHTML += resultHTML;
    });
  }

  setHeadings(data, type) {
    const meta = document.querySelector(`.search-results .${type} .meta`);

    const metaHeading = document.createElement('p');
    if (type == 'pages') {
      metaHeading.innerHTML = jsData.words.headingOffer;
    } else {
      metaHeading.innerHTML = jsData.words.headingPublications;
    }

    const metaCount = document.createElement('p');
    metaCount.innerHTML = data.length;

    meta.innerHTML = '';
    meta.appendChild(metaHeading);
    meta.appendChild(metaCount);
  }
}
